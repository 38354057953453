<template>
  <div id="meetingList">
    <v-card>
      <!-- HEADER TOOLBAR -->
      <v-toolbar class="page-primary-card-header">
        <v-list-item dark>
          <v-list-item-avatar color="primary lighten-2">
            <v-icon>{{card_header_props.header.icon}}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="headline">{{card_header_props.header.headLine}}</v-list-item-title>
            <v-list-item-subtitle>{{card_header_props.header.subTitle}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-btn
          @click="card_header_props.helper.page_helper = !card_header_props.helper.page_helper"
          icon
          dark
        >
          <v-icon>info</v-icon>
        </v-btn>

        <v-dialog
          v-model="card_header_props.helper.page_helper"
          width="500"
          :active.sync="card_header_props.helper.page_helper"
        >
          <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>{{ this.$t('_common.How_can_I_use_this_form')}}</v-card-title>

            <v-card-text>{{ this.$t('_meeting_list._info.content') }}</v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="card_header_props.helper.page_helper = false">{{ this.$t('_common.OK') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <!-- HEADER TOOLBAR -->

      <!-- MAIN VIEW -->
      <v-flex class="p-4">
        <v-sheet class="mx-auto" elevation="8"
                 style="width:100%;"
                 v-if="meetings.length > 0">
          <v-slide-group
            v-model="model"
            class="pa-4"
            center-active
            show-arrows
            v-for="meetingKey in meetingGroupKeys"
            :key="`meeting-${meetingKey}`"
          >
            <v-slide-item
              v-for="meeting in meetingGroupList[meetingKey]"
              :key="`meeting-${meeting.AssignedDate}`"
              v-slot:default="{ active, toggle }"
              :style="shortMeetingList(meetingGroupList[meetingKey])"
            >
              <v-hover v-slot:default="{ hover }">
                <v-flex style="padding: 0 10px 20px 10px; max-width: 250px;">
                  <v-card
                    :elevation="hover ? 8 : 2"
                    class="mx-auto"
                    height="290"
                    width="250"
                    @click="onClickMeeting(meeting)"
                  >
                    <v-img :aspect-ratio="16/9" src="/assets/noimage.png">
                      <v-avatar
                        v-if="hover"
                        color="white"
                        size="36"
                        class="centered"
                        style="position: absolute; top:0; bottom: 0; left: 0; right: 0; margin: auto;"
                      >
                        <v-icon color="black">mdi-video</v-icon>
                      </v-avatar>
                      <v-avatar size="25"></v-avatar>
                    </v-img>
                    <v-card-text class="pt-6" style="position: relative;">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <h5 class="truncate font-semibold" v-on="on">{{meeting.Title}}</h5>
                        </template>
                        <span>{{$t('_meeting_list.Click_to_attend_meeting')}}</span>
                      </v-tooltip>
                      <div class="font-semibold truncate-long">{{$t('_meeting_list.Meeting_assigned_at')}}</div>
                      <div>
                        <b>{{ meeting.StartDate | moment("dddd, MMMM Do YYYY") }}</b>
                      </div>
                      <div>{{ meeting.StartDate | moment("h:mm:ss a") }}</div>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-hover>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
        <v-card v-else class="mx-auto" elevation="0">
          <v-card-text>
            <p class="display-1 text--primary text-center">{{ $t('_meeting_list.No_meetings_found') }}</p>
            <p>{{ this.$t('_meeting_list.No_meetings_found_Text') }}</p>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-card>
  </div>
</template>

<script>
  require("../../assets/css/neo.css");

  export default {
    data() {
      return {
        model: null,
        meetingGroupKeys: [],
        meetingGroupList: {},
        card_header_props: {
          header: {
            headLine: this.$t('_verticalNavbar.Meeting_List'),
            subTitle: this.$t('_meeting_list.Start_Meeting_Text'),
            icon: "videocam"
          },
          helper: {
            absolute: true,
            opacity: 0.8,
            overlay: false,
            page_helper: false
          }
        },
        meetings: []
      };
    },
    methods: {
      init() {
        this.getAssignedMeetings();
      },
      shortMeetingList(meetings) {
        if (meetings.length < 4) {
          return "margin-left: 52px;";
        } else {
          return "margin-left: 0px;";
        }
      },
      getAssignedMeetings() {
        let _self = this;
        let uid = JSON.parse(localStorage.getItem("userInfo")).Uid;
        let groupObj = {};
        this.$goc.request.get(this.$enums.API.Meeting_User + `${uid}`, response => {
          let meetingsReverse = response.Result.Meetings;
          meetingsReverse.map(e => {
            let key = _self.$moment(e.AssignedDate).format("YYYY_MM");
            return groupObj[key] ? groupObj[key].push(e) : (groupObj[key] = [e]);
          });
          _self.meetingGroupKeys = Object.keys(groupObj);
          _self.meetingGroupList = groupObj;
          _self.meetings = meetingsReverse;
        });
      },
      onClickMeeting(meeting) {
        let userRole = JSON.parse(localStorage.getItem("userInfo")).UserRoles;
        const meetingUrl = `${this.$enums.URL.meeting}${meeting.Url}`
        if(userRole != "LmsAdministrator" || userRole != "LmsTeacher")
          window.open(meetingUrl, "_blank")
        else
          window.open(meetingUrl + "?token=" + meeting.Key, "_blank")
      },
      fancyTimeFormat() {
      }
    },
    mounted: function () {
      this.init();
    },

    name: "MeetingList"
  };
</script>

<style scoped>
</style>
